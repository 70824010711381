<template>
    <div>
        <el-form :model="form_data" :rules="formRules" :ref="form_name" :disabled="formDisabled" size="mini" label-position="right" label-width="100px">
            <el-form-item label="名称:" prop="title"><el-input v-model="form_data.title"></el-input></el-form-item>
            <el-form-item label="充值类型:" prop="recharge_type">
                <el-select v-model="form_data.recharge_type" filterable placeholder="请选择充值类型">
                    <el-option v-for="(p) in params.recharge_type" :key="p.key" :label="p.value" :value="p.key"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="充值时长:" prop="recharge_duration">
                <el-select v-model="form_data.recharge_duration" filterable placeholder="请选择充值时长">
                    <el-option v-for="(p) in params.recharge_duration" :key="p.key" :label="p.value" :value="p.key"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="划线价:" prop="crossed_price"><el-input-number :step="0.1" :min="0.01" v-model="form_data.crossed_price"></el-input-number></el-form-item>
            <el-form-item label="实际价格:" prop="price"><el-input-number :step="0.1" :min="0.01" v-model="form_data.price"></el-input-number></el-form-item>
            <el-form-item label="说明:" prop="description"><el-input v-model="form_data.description"></el-input></el-form-item>
            <el-form-item label="排序:" prop="sort"><el-input-number v-model="form_data.sort" :step="1"></el-input-number></el-form-item>
            <el-form-item label="状态:" prop="status">
                <el-radio-group v-model="form_data.status">
                    <el-radio-button :label="1">启用</el-radio-button>
                    <el-radio-button :label="2">禁用</el-radio-button>
                </el-radio-group>
            </el-form-item>
        </el-form>
        <div style="text-align:right;">
            <el-button v-if="action!=='view'" type="primary" @click="submitForm(form_name)" size="mini">提交编辑</el-button>
            <el-button @click="onCloseDialog" size="mini">关闭</el-button>
        </div>
    </div>
</template>

<script>
    import {getParams} from "@/api/hado/param";
    import{getDetail,submit} from "@/api/hado/user-recharge-package";

    const param_list = ['recharge_type','recharge_duration']
    export default {
        name: "user-recharge-package-detail",
        props:{
            action:{
                value:String,
                default:'add'
            },
            rowId:{
                value:String,
            }
        },
        data(){
            return{
                form_name:'user_recharge_package_detail',
                form_data:{crossed_price:0.00,price:0.00},
                formRules:{},
                params:{},
                params_map:{},
                params_map_reverse:{},
            }
        },
        computed:{
            formDisabled(){
                return this.action === 'view' || !this.action;
            }
        },
        created(){
            this.initPage()
        },
        methods:{
            initParams(){
                return new Promise((resolve,reject)=>{
                    getParams(param_list.join(','),'zh-CN').then(resp=>{
                        this.params = resp.data;

                        let tmp_params_map = {}
                        let tmp_params_map_reverse = {}

                        //这里再这里一下每组参数做成kv方便显示
                        let p_keys = Object.keys(this.params)
                        p_keys.forEach(pl=>{
                            console.log('pl:',pl)
                            let tmp_map = {}
                            let tmp_map_reverse = {}
                            this.params[pl].forEach(pm=>{
                                // tmp_map[pm.key]=pm.value
                                tmp_map[pm.key]={value:pm.value,color:pm.color}
                                //再调转处理一下 导入Excel参数解析用
                                tmp_map_reverse[pm.value]=pm.key
                            })
                            tmp_params_map[pl] = tmp_map
                            tmp_params_map_reverse[pl] = tmp_map_reverse
                        })

                        this.params_map = tmp_params_map
                        this.params_map_reverse = tmp_params_map_reverse
                        console.log('params_map:',this.params_map)
                        console.log('params_map_reverse:',this.params_map_reverse)
                        resolve(resp)
                    }).catch((err)=>{
                        if(err){
                            console.error(err)
                        }
                        reject(err)
                    })
                });
            },
            initPage(){
                this.initParams().then(()=>{
                    if(this.rowId>0){
                        getDetail(this.rowId).then(resp=>{
                            this.form_data = resp.data
                        })
                    }
                })
            },
            onCloseDialog(){
                this.$emit("onTellDialogClose")
            },
            submitForm(formName){
                this.$refs[formName].validate((valid)=>{
                    if(valid){
                        submit(this.form_data).then(()=>{
                            this.$emit('onTellDialogClose')
                        })
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>
